<template>
  <Title title="Productos" />
  <ContentMain>
    <template v-slot:list>
      <Search />
      <div class="content-grid-list-items scroll">
        <Item />
        <!-- <Skeleton skeleton="item" /> -->
      </div>
    </template>
    <template v-slot:form>
      <!-- <FormSkeleton /> -->
      <FormMainProduct />
    </template>
  </ContentMain>
  <Fab text="Producto" />
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { onBeforeMount } from 'vue';

import useAuthentication from '@/modules/auth/composables/useAuthentication';

export default {
  components: {
    Title: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Title" */ '@/modules/cms/components/Title.vue'
      )
    ),
    ContentMain: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "ContentMain" */ '@/modules/cms/components/ContentMain.vue'
      )
    ),
    Search: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Search" */ '@/modules/cms/components/Search.vue'
      )
    ),
    Item: defineAsyncComponent(() =>
      import(/* webpackChunkName: "Item" */ '@/modules/cms/components/Item.vue')
    ),
    Fab: defineAsyncComponent(() =>
      import(/* webpackChunkName: "Fab" */ '@/modules/cms/components/Fab.vue')
    ),
    Skeleton: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Skeleton" */ '@/modules/cms/components/skeletons/Skeleton.vue'
      )
    ),

    FormSkeleton: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "FormSkeleton" */ '@/modules/cms/parts/products/FormSkeleton.vue'
      )
    ),
    FormMainProduct: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "FormMainProduct" */ '@/modules/cms/parts/products/FormMainProduct.vue'
      )
    ),
  },

  setup() {
    onBeforeMount(() => {
      const { validateAuthentication } = useAuthentication();
      validateAuthentication('products');
    });
  },
};
</script>
